import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/sales/license-trial',
        component: () => import('@/layout/LicenseTrialLayout.vue'),
        redirect: { name: 'WaitingApproval' },
        children: [
            {
                path: 'menunggu-approval',
                name: 'WaitingApproval',
                component: () => import('@/views/SalesLicenseTrialWaitingApprovalView.vue'),
            },
            {
                path: 'ditolak',
                name: 'Rejected',
                component: () => import('@/views/SalesLicenseTrialRejectedView.vue'),
            },
            {
                path: 'diproses',
                name: 'InProgress',
                component: () => import('@/views/SalesLicenseTrialInProgressView.vue'),
            },
            {
                path: 'aktif',
                name: 'Active',
                component: () => import('@/views/SalesLicenseTrialActiveView.vue'),
            },
            {
                path: 'expired',
                name: 'Expired',
                component: () => import('@/views/SalesLicenseTrialExpiredView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
